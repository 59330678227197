import { useEffect, useState } from "react";
import arrow from "../img/icons/arrow-right-white.svg";

const ToTop = () => {
  const [offset, setOffset] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.scrollY >= 1) setOffset(true);
      else setOffset(false);
    });
    return () =>
      window.removeEventListener("scroll", (e) => {
        if (window.scrollY >= 1) setOffset(true);
        else setOffset(false);
      });
  }, []);

  return window.location.pathname.includes("sights") ? null : (
    <div
      onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
      className={`to-top ${offset ? "" : "hidden-to-top"}`}>
      <img src={arrow} />
    </div>
  );
};

export default ToTop;
