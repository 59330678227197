//COMPONENTS START

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import "swiper/swiper.min.css";
import Redirect from "./components/Redirect.js";
//COMPONENTS END

//MYCOMPONENTS START

import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";

//MYCOMPONENTS END

import "./sass/page.scss";
import i18next from "i18next";
import Popup from "./pages/HomeNew/Popup.js";
import Drinks from "./pages/Drinks.js";
import ToTop from "./components/ToTop.js";

const Preloader = lazy(() => import("./components/Preloader/Preloader"));
const SchrollToTop = lazy(() => import("./components/SchrollToTOp/index"));
const Layout = lazy(() => import("./components/Layout/index"));
const Aug20 = lazy(() => import("./pages/Aug20"));
const GalleryPage = lazy(() => import("./pages/GalleryPage"));
const Tortenelem = lazy(() => import("./pages/Tortenelem"));
const Rendezvenyek = lazy(() => import("./pages/Rendezvenyek"));
const CegesRendezvenyek = lazy(() => import("./pages/CorporateEvent/index.js"));
const CsRendezvenyek = lazy(() => import("./pages/FamilyEvent/index.js"));
// const FotoEsForgatas = lazy(() => import("./pages/PhotoEvent/index.js"));
const Eskuvo = lazy(() => import("./pages/Wedding/index.js"));
const NotFoundPage = lazy(() => import("./pages/404/index"));
const BoxOfficeRedirect = lazy(() => import("./pages/Ticket/BoxOfficeRedirect"));
const TicketRedirect = lazy(() => import("./pages/Ticket/TicketRedirect"));
// const Career = lazy(() => import("./pages/Career/index"));
const Ticket = lazy(() => import("./pages/Ticket/Ticket"));
const Login = lazy(() => import("./pages/Login/Login"));
const Home = lazy(() => import("./pages/HomeNew/Home"));
//PAGES END

//CSS
// import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [isLoad, setLoad] = React.useState(true);
  const [inDOM, setInDOM] = React.useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    window.onload = setTimeout(() => {
      setLoad(false);
      const tagManagerArgs = {
        gtmId: "GTM-MLZKZ7K",
      };
      TagManager.initialize(tagManagerArgs);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!isLoad) {
      setTimeout(() => {
        setInDOM(false);
      }, 500);
    }
  }, [isLoad]);
  const lang = i18next.language;
  return (
    <>
      <Helmet>
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.desc")}></meta>
        <meta name="og:description" content={t("meta.desc")}></meta>
        <meta name="og:title" content={t("meta.title")}></meta>
      </Helmet>
      {inDOM && <Preloader className={`${isLoad ? "loading" : "loaded"}`} />}
      <Popup t={t} />
      <ToTop t={t} />
      <Suspense fallback={<div></div>}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Layout>
                    <SchrollToTop />
                    <Home isLoad={isLoad} setLoad={setLoad} />
                  </Layout>
                }></Route>
              <Route
                path="/rendezvenyek"
                element={
                  <Layout>
                    <SchrollToTop />
                    <Rendezvenyek />
                  </Layout>
                }></Route>
              <Route
                path="/eskuvo-rendezvenyek"
                element={
                  <Layout>
                    <SchrollToTop />
                    <Eskuvo />
                  </Layout>
                }></Route>
              <Route
                path="/csaladi-rendezvenyek"
                element={
                  <Layout>
                    <SchrollToTop />
                    <CsRendezvenyek />
                  </Layout>
                }></Route>
              <Route
                path="/ceges-rendezvenyek"
                element={
                  <Layout>
                    <SchrollToTop />
                    <CegesRendezvenyek />
                  </Layout>
                }></Route>
              {/* <Route
                path="/foto-es-forgatas"
                element={
                  <Layout>
                    <SchrollToTop />
                    <FotoEsForgatas />
                  </Layout>
                }></Route> */}
              <Route
                path="/tortenelem"
                element={
                  <Layout>
                    <SchrollToTop />
                    <Tortenelem />
                  </Layout>
                }></Route>
              {/* <Route path="/kisfaludybar">
                <Layout>
                  <SchrollToTop />
                  <KisfaludyBar />
                </Layout>
              </Route> */}
              {/* 
              <Route path="/karrier">
                <Layout>
                  <SchrollToTop />
                  <Career />
                </Layout>
              </Route> */}
              <Route
                path="/jegyvasarlas"
                element={
                  <Layout>
                    <SchrollToTop />
                    <Ticket />
                  </Layout>
                }></Route>
              <Route
                path="/transaction"
                element={
                  <Layout>
                    <SchrollToTop />
                    <TicketRedirect />
                  </Layout>
                }></Route>
              <Route
                path="/login"
                element={
                  <Layout>
                    <SchrollToTop />
                    <Login />
                  </Layout>
                }></Route>

              <Route
                path="/box-office"
                element={
                  <Layout>
                    <SchrollToTop />
                    <BoxOfficeRedirect />
                  </Layout>
                }></Route>

              <Route
                path="/aug20"
                element={
                  <Layout>
                    <SchrollToTop />
                    <Aug20 />
                  </Layout>
                }></Route>
              <Route
                path="/gallery"
                element={
                  <Layout>
                    <SchrollToTop />
                    <GalleryPage />
                  </Layout>
                }></Route>
              <Route
                path="/drinks"
                element={
                  <Layout>
                    <SchrollToTop />
                    <Drinks t={t} />
                  </Layout>
                }></Route>
              <Route
                path="/drinks-qr-plakett"
                element={
                  <Layout>
                    <SchrollToTop />
                    <Drinks t={t} />
                  </Layout>
                }></Route>
              <Route
                path="/sights"
                element={
                  <Redirect
                    to={"https://www.dunacruises.com"}
                    path="sights?kf=true"
                    lang={lang ? lang : "en"}
                  />
                }></Route>
              <Route
                path="*"
                element={
                  <Layout>
                    <NotFoundPage />
                  </Layout>
                }></Route>
            </Routes>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </Suspense>
    </>
  );
};

export default App;
