const INITIAL_STATE = {
  isModal: true,
  isSeasonEnd: true,
  isValentines: true,
};

const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_MODAL":
      return {
        ...state,
        isModal: action.payload,
      };
    case "SET_POPUP":
      return {
        ...state,
        isSeasonEnd: action.payload,
      };
    case "SET_VALENTINES":
      return {
        ...state,
        isValentines: action.payload,
      };
    default:
      return state;
  }
};

export default modalReducer;
